const INFO = {
	main: {
		title: "UI Programming Portfolio by mikeharris",
		name: "Mike Harris",
		email: "jammin804@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/jammin804",
		linkedin: "https://www.linkedin.com/in/michaelharrisj/",
	},

	homepage: {
		title: "UI Game Progammer and User Experience Advocate.",
		description:
			"I am a passionate User Interface (UI) Game Programmer dedicated to crafting immersive and intuitive user experiences for players. With a strong foundation in game development and a keen eye for design, I specialize in creating seamless and engaging interfaces that elevate the gaming experience.",
	},

	about: {
		title: "Hi! I'm Mike Harris. Nice to meet you",
		description:
		"I bring a unique blend of technical expertise and creative flair to the world of game development. My journey as a UI Game Programmer has equipped me with skills in various programming languages, design tools, and game engines. I thrive in collaborative environments and am driven by a commitment to delivering polished, user-centric interfaces.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Steampunk UI Prototype",
			description:
				"This prototype showcases, intuitive interface designed to enhance user experience through smooth navigation and responsive controls. The UI Navigation plugin enabled me to quickly implement the controller and keyboard navigation in the main menu.",
			logo: "https://cdn.jsdelivr.net/npm/simple-icons@3.13.0/icons/epicgames.svg",
			linkText: "View Project",
			link: "https://github.com/jammin804/SteamPunkMenuUE5",
		},
		
		{
			title: "Moth Attack - C++ game using Unreal Engine",
			description:
				"Using Unreal Engine, C++, created a survival horror game that showcased AI, Animation, Gunplay, and UI. I utilized the Unreal Engine’s AI behavior trees, environmental query systems (EQS), and AI perception alongside a finite state machine (FSM) in order to create a dynamic and challenging experience for the player.",
			logo: "https://cdn.jsdelivr.net/npm/simple-icons@3.13.0/icons/epicgames.svg",
			linkText: "View Project",
			link: "https://github.com/jammin804/MothAttack",
		},

		{
			title: "Purgatory - C++ game using the Allegro 5 Library",
			description:
				"Using a game template, I was able to create a 2D Roguelike game with finite state machine AI, box collision, powerups, and pickups. By utilizing finite state machines, I was easily able to manipulate the player and enemies state and animations. Created a power-up shop system where the player, uses the currency gained from defeating enemies in order to increase their stats. Leveraged debugging methodology in order to fix bugs and improve performance.",
			logo: "https://cdn.jsdelivr.net/npm/simple-icons@3.13.0/icons/cplusplus.svg",
			linkText: "View Project",
			link: "https://github.com/jammin804/Purgatory",
		},

		

		// {
		// 	title: "Project 4",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },

		// {
		// 	title: "Project 5",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
	],
};

export default INFO;
